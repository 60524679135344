/*
  [x] 100vh fixed height ohne scrollen des gesamten documents (browsers bars bleiben immer da, kein scrollen auf html oder body)
  [x] Desktop Navigation, Refactor von URL-Hash / Scroll / Swipe
  [x] button für video controls (mute, reset)
  [ ] probieren: halten für video pause
*/

html, body {
  width: 100vw;
  height: var(--app-height);
  margin: 0;
  padding: 0;
  overflow: hidden;

  font-family: sans-serif;
}

body {
  overscroll-behavior: contain;
}

* {
  box-sizing: border-box;

  -webkit-user-select: none; /* disable selection/Copy of UIWebView */
  -webkit-touch-callout: none; /* disable the IOS popup when long-press on a link */
}

h1 {
  text-align: center;
}

button  {
  padding: 10px;

  color: black;
  font-weight: bold;
  font-size: 16px;

  background: transparent;
  border: 3px solid black;
  border-radius: none;
}

button:hover {
  color: white;

  background: black;
  cursor: pointer;
}

.feed {
  position: relative;

  width: 100vw;
  height: var(--app-height);
  overflow: hidden;
  overflow-y: scroll;
}

.feed__item {
  position: relative;

  width: 100vw;
  height: var(--app-height);
  min-height: var(--app-height);
  overflow: hidden;

  border: 10px solid black;
}

.feed__item-body {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 2;

  background: rgba(255, 255, 255, 0.3);
}

.feed__item-body-video-ended {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 20px;

  background: rgba(255, 255, 255, 0.3);
}

.feed__item-body-video-ended-related-posts {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
}

.feed__item-body-video-ended-related-posts-post {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: space-between;
}

.feed__item-body-video-paused {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.feed__item-body-video-controls {
  position: absolute;
  right: 0px;
  bottom: 80px;
  left: 0px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.feed__item-video {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: -1;
}

.feed__item-benchmark {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: -1;
}

.benchmark {
  position: relative;

  width: 100%;
  height: var(--app-height);
}

.benchmark__body {
  display: none;
}

.benchmark__body--visible {
  display: block;
}

.video {
  position: relative;

  width: 100%;
  height: var(--app-height);
}

.video video {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  width: 100%;
  height: var(--app-height);
  object-fit: contain;
  object-position: center center;
}

.video__loading {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  text-align: center;
}

.desktop-navigation__routes {
  position: fixed;
  bottom: 0px;

  display: none;
  align-items: center;
  justify-content: space-around;
  width: 100vw;
  padding: 10px;

  background: white;
}

.splide__arrows {
  position: absolute;
  top: 50%;
  right: 0px;
  z-index: 3;

  display: none;
  width: 50px;
  height: 150px;

  transform: translateY(-50%);
}

@media (hover: hover) {
  .desktop-navigation__routes, .desktop-navigation__feed {
    display: flex;
  }
  .desktop-navigation__feed {
    scroll-behavior: smooth;
  }

  .splide__arrows {
    display: block;
  }
}

.loading-screen {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;
}